import api from './index'
import Vue from 'vue'
import { axios } from '@/utils/request'
import Qs from 'qs' // 关于qs使用 https://blog.csdn.net/nihaoqiulinhe/article/details/79084755
import notification from 'ant-design-vue/es/notification'
import { ACCESS_TOKEN } from '@/store/mutation-types'
if (api.MockTrue === 0) {
  //   import mock from '@/mock/mock.js'
  // const mock = require('@/mock/mock.js')
  // console.log(mock)
  }
export function paramReq (url, parameter, successFunc, errFunc, method = 'post') {
  const token = Vue.ls.get(ACCESS_TOKEN)
    return axios({
      url: method === 'post' ? url : url + '?' + Qs.stringify(parameter),
      method: method,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Token': token
      },
      data: Qs.stringify(parameter)
    }).then(response => {
      if (response.code !== 0 && response.code !== 160 && response.code !== 254) {
        notification.error({
          message: '请求失败',
          description: response.errMsg
        })
        console.log(response)
        console.error(response.errMsg)
        errFunc(response.errMsg)
      } else if (response.code === 254 || response.code === 255) {
        console.log(response)
        console.error(response.errMsg)
        errFunc(response.errMsg, response.code)
    } else if (response.code === 160) {
          if (token) {
            notification.error({
              message: '登录失效',
              description: '即将跳转至校园OA，请重新登录',
              onClose: function(){
                // window.location.href = 'https://zhxzadmin.xueyoyo.com/'
                console.log('我即将关闭')
              }
            })

          console.log('发现登录失效')
          }
      } else {
        successFunc(response)
      }
    }).catch(err => {
      console.error(err)
      errFunc(err)
    })
}

/* function tableReq (url, parameter) {
  return axios({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: Qs.stringify(parameter)
  })
} */

export function jsonReq (url, parameter, successFunc, errFunc) {
  const token = Vue.ls.get(ACCESS_TOKEN)
    return axios({
      url: url,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Access-Token': token
      },
      data: parameter,
      transformRequest: [function (data) {
        data = JSON.stringify(data)
        return data
      }]
    }).then(response => {
      if (response.code !== 0) {
        notification.error({
          message: '请求失败',
          description: response.errMsg
        })
        console.error(response.errMsg)
        errFunc(
          response.errMsg)
      } else {
        successFunc(response)
      }
    }).catch(err => {
      console.error(err)
      errFunc(err)
    })
}
export function jsonGetReq (url, parameter, successFunc, errFunc) {
  const token = Vue.ls.get(ACCESS_TOKEN)
    return axios({
      url: url,
      method: 'get',
      headers: {
        'Content-Type': 'json',
        'Access-Token': token
      },
      data: parameter,
     /*  transformRequest: [function (data) {
        // data = JSON.stringify(data)
        return data
      }] */
    }).then(response => {
      if (response.code !== 0) {
        notification.error({
          message: '请求失败',
          description: response.errMsg
        })
        console.error(response.errMsg)
        errFunc(
          response.errMsg)
      } else {
        successFunc(response)
      }
    }).catch(err => {
      console.error(err)
      errFunc(err)
    })
}
// 德育综合考评
// export function bigscreenLeft (parameter, successFunc, errFunc) {
//   return paramReq(api.bigscreenLeft, parameter, successFunc, errFunc, 'get')
// }
// 模块列表查询
export function modelsQuery (parameter, successFunc, errFunc) {
  return paramReq(api.modelsQuery, parameter, successFunc, errFunc)
}
// 考核小项统计
// export function bigscreenLeftBottom (parameter, successFunc, errFunc) {
//   return paramReq(api.bigscreenLeftBottom, parameter, successFunc, errFunc, 'get')
// }
// 事件提醒
export function warningStu (parameter, successFunc, errFunc) {
  return paramReq(api.warningStu, parameter, successFunc, errFunc, 'get')
}
// 学风排名
export function bigscreenLeftV2 (parameter, successFunc, errFunc) {
  return paramReq(api.bigscreenLeftV2, parameter, successFunc, errFunc, 'get')
}
// 学习进度
export function bigscreenStudyProgressV2 (parameter, successFunc, errFunc) {
  return paramReq(api.bigscreenStudyProgressV2, parameter, successFunc, errFunc, 'get')
}
// 电子资料上传
export function bigscreenTeacherMateriaPies (parameter, successFunc, errFunc) {
  return paramReq(api.bigscreenTeacherMateriaPies, parameter, successFunc, errFunc, 'get')
}
// 自主学习英雄榜
export function bigscreenStudyHeros (parameter, successFunc, errFunc) {
  return paramReq(api.bigscreenStudyHeros, parameter, successFunc, errFunc, 'get')
}
// 攻坚克难
export function bigscreenStudyAssessmentV2 (parameter, successFunc, errFunc) {
  return paramReq(api.bigscreenStudyAssessmentV2, parameter, successFunc, errFunc)
}
// 教师备案
export function bigscreenTeacherMateria (parameter, successFunc, errFunc) {
  return paramReq(api.bigscreenTeacherMateria, parameter, successFunc, errFunc, 'get')
}
// 教师批改
export function bigscreenWarningTeacher (parameter, successFunc, errFunc) {
  return paramReq(api.bigscreenWarningTeacher, parameter, successFunc, errFunc, 'get')
}
// 分校大屏列表
export function bigScreenUIList (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenUIList, parameter, successFunc, errFunc)
}
//  分校大屏修改
export function bigScreenUIUpdateName (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenUIUpdateName, parameter, successFunc, errFunc)
}
// 分校大屏删除
export function bigScreenUIDel (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenUIDel, parameter, successFunc, errFunc)
}
// 获取天气
export function bigscreenWarningReport (parameter, successFunc, errFunc) {
  return paramReq(api.bigscreenWarningReport, parameter, successFunc, errFunc)
}
// 发布大屏
export function bigscreenUiPublish (parameter, successFunc, errFunc) {
  return paramReq(api.bigscreenUiPublish, parameter, successFunc, errFunc)
}
// 获取大屏信息
export function bigscreenUiGet (parameter, successFunc, errFunc) {
  return paramReq(api.bigscreenUiGet, parameter, successFunc, errFunc)
}
// 查询分校学科
export function xuekeSimpleList (parameter, successFunc, errFunc) {
  return paramReq(api.xuekeSimpleList, parameter, successFunc, errFunc)
}
// 查询分校年级
export function gradeListSimple (parameter, successFunc, errFunc) {
  return paramReq(api.gradeListSimple, parameter, successFunc, errFunc)
}
// 查询分校考核大项
export function assessmentSettingListSimple (parameter, successFunc, errFunc) {
  return paramReq(api.assessmentSettingListSimple, parameter, successFunc, errFunc)
}
// 保存大屏
export function bigScreenUiSsave (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenUiSsave, parameter, successFunc, errFunc)
}
// 修改大屏地址
export function bigScreenUrlChange (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenUrlChange, parameter, successFunc, errFunc)
}
// 创建空分校大屏
export function bigScreenCreate (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenCreate, parameter, successFunc, errFunc)
}
// 使用token换用户信息
export function getToken (parameter, successFunc, errFunc) {
  return paramReq(api.getToken, parameter, successFunc, errFunc)
}
// 使用token换用户头像名称等
export function authInfo (parameter, successFunc, errFunc) {
  return paramReq(api.authInfo, parameter, successFunc, errFunc)
}
// 获取模块样式
export function bigScreenInSameModel (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenInSameModel, parameter, successFunc, errFunc)
}
// 大屏德育考核评价落实
export function assessmentLuoshi (parameter, successFunc, errFunc) {
  return paramReq(api.assessmentLuoshi, parameter, successFunc, errFunc)
}
// 大屏德育考核评价落实-走班课堂日志
export function assessmentLuoshiZouban (parameter, successFunc, errFunc) {
  return paramReq(api.assessmentLuoshiZouban, parameter, successFunc, errFunc)
}
// 获取攻坚克难成果展
export function bigScreenShowType2 (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenShowType2, parameter, successFunc, errFunc)
}
// 获取攻坚克难成果展
export function assessmentSettingSimple (parameter, successFunc, errFunc) {
  return paramReq(api.assessmentSettingSimple, parameter, successFunc, errFunc)
}
// 获取德育周考核排名
export function bigScreenWeek (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenWeek, parameter, successFunc, errFunc)
}
// 大屏德育月考核评价汇总
export function assessmentUploadCount (parameter, successFunc, errFunc) {
  return paramReq(api.assessmentUploadCount, parameter, successFunc, errFunc)
}
// 大屏近期作业完成情况
export function bigScreenHomeWork (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenHomeWork, parameter, successFunc, errFunc)
}
// 大屏专题复习学习进度
export function batchStudy (parameter, successFunc, errFunc) {
  return paramReq(api.batchStudy, parameter, successFunc, errFunc)
}
// 教师本月试题上传数量
export function bigScreenUploadQuestion (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenUploadQuestion, parameter, successFunc, errFunc)
}
// 联屏列表
export function scrollShowQuery (parameter, successFunc, errFunc) {
  return paramReq(api.scrollShowQuery, parameter, successFunc, errFunc)
}
// 设置或添加联屏
export function scrollShowSet (parameter, successFunc, errFunc) {
  return paramReq(api.scrollShowSet, parameter, successFunc, errFunc)
}
// 删除联屏
export function scrollShowDel (parameter, successFunc, errFunc) {
  return paramReq(api.scrollShowDel, parameter, successFunc, errFunc)
}
// 总校大屏列表
export function uiListBySchool (parameter, successFunc, errFunc) {
  return paramReq(api.uiListBySchool, parameter, successFunc, errFunc)
}
// 校园人员总览
export function subSchoolPeopleCount (parameter, successFunc, errFunc) {
  return paramReq(api.subSchoolPeopleCount, parameter, successFunc, errFunc)
}
// 教师本月备课课时完成情况
export function bigScreenTeacherMateriaKeshi (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenTeacherMateriaKeshi, parameter, successFunc, errFunc)
}
// 教师本月布置作业数
export function bigScreenTeacherHomework (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenTeacherHomework, parameter, successFunc, errFunc)
}
// 大屏校训
export function subSchoolMotto (parameter, successFunc, errFunc) {
  return paramReq(api.subSchoolMotto, parameter, successFunc, errFunc)
}
// 教师规范课时
export function bigScreenTeacherGoodKeshi (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenTeacherGoodKeshi, parameter, successFunc, errFunc)
}
// 违规使用平板学生
export function padUnlawful (parameter, successFunc, errFunc) {
  return paramReq(api.padUnlawful, parameter, successFunc, errFunc)
}
// 纠错排行榜
export function bigScreenErrorCorrecting (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenErrorCorrecting, parameter, successFunc, errFunc)
}
// 自习室学生实时学习成果（教室）
export function bigScreenZixishiStudying (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenZixishiStudying, parameter, successFunc, errFunc)
}
// 自习室做题数量实时榜（教室）
export function bigScreenZixishiStudyingQuestionCountSort (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenZixishiStudyingQuestionCountSort, parameter, successFunc, errFunc)
}
// 自习室获得积分实时榜（教室）
export function bigScreenZixishiStudyingQuestionIntegralSort (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenZixishiStudyingQuestionIntegralSort, parameter, successFunc, errFunc)
}
// 自习室教室
export function bigScreenZixishiClassRoom (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenZixishiClassRoom, parameter, successFunc, errFunc)
}
// 自习室课时（教室）
export function bigScreenZixishiClassRoomKeshi (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenZixishiClassRoomKeshi, parameter, successFunc, errFunc)
}
// 自习室教室统计（教室）
export function bigScreenZixishiStudyingTop (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenZixishiStudyingTop, parameter, successFunc, errFunc)
}
// 自习室做题类型占比（教室）
export function bigScreenZixishiStudyingQuestionType (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenZixishiStudyingQuestionType, parameter, successFunc, errFunc)
}
// 自习室答题实时率实时榜（教室）
export function bigScreenZixishiStudyingQuestionRightSort (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenZixishiStudyingQuestionRightSort, parameter, successFunc, errFunc)
}
// 自习室学生实时学习成果（全部）
export function bigScreenZixishiOutSideLearningCurrent (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenZixishiOutSideLearningCurrent, parameter, successFunc, errFunc)
}
// 自习室班级做题数量排行榜
export function bigScreenZixishiOutSideLearningCount (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenZixishiOutSideLearningCount, parameter, successFunc, errFunc)
}
// 自习室学生获得积分排行榜（全部）
export function bigScreenZixishiOutSideLearningIntegral (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenZixishiOutSideLearningIntegral, parameter, successFunc, errFunc)
}
// 自习室学生做题趋势（全部）
export function bigScreenZixishiOutSideLearningCountLine (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenZixishiOutSideLearningCountLine, parameter, successFunc, errFunc)
}
// 自习室学生答对数量排行榜（全部）
export function bigScreenZixishiOutSideLearningRightLine (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenZixishiOutSideLearningRightLine, parameter, successFunc, errFunc)
}
// 班级做题统计
export function bigScreenBclassLearningCount (parameter, successFunc, errFunc) {
  return paramReq(api.bigScreenBclassLearningCount, parameter, successFunc, errFunc)
}
// 违规使用平板学生
export function padgroupByClassUnlawful (parameter, successFunc, errFunc) {
  return paramReq(api.padgroupByClassUnlawful, parameter, successFunc, errFunc)
}
// 分校电子班牌分页列表
export function electronicCardListPage (parameter, successFunc, errFunc) {
  return paramReq(api.electronicCardListPage, parameter, successFunc, errFunc)
}
// 分校电子班牌分页列表
export function getBulletinBoardContent (parameter, successFunc, errFunc) {
  return paramReq(api.getBulletinBoardContent, parameter, successFunc, errFunc)
}
export function classBoardExcellentList (parameter, successFunc, errFunc) {
  return paramReq(api.classBoardExcellentList, parameter, successFunc, errFunc)
}
export function classBoardHeros (parameter, successFunc, errFunc) {
  return paramReq(api.classBoardHeros, parameter, successFunc, errFunc)
}
// 分校查班级
export function bclassSimple (parameter, successFunc, errFunc) {
  return paramReq(api.bclassSimple, parameter, successFunc, errFunc)
}
// 班级公告
export function getGgByPageForWeb (parameter, successFunc, errFunc) {
  return paramReq(api.getGgByPageForWeb, parameter, successFunc, errFunc)
}
// 学生平板班级请假学生列表
export function getStuLeavesByBclassId (parameter, successFunc, errFunc) {
  return paramReq(api.getStuLeavesByBclassId, parameter, successFunc, errFunc)
}
// 学校公告
export function getGgSubByPageForWeb (parameter, successFunc, errFunc) {
  return paramReq(api.getGgSubByPageForWeb, parameter, successFunc, errFunc)
}
// 获取表扬或批评信息
export function getPraiseInfo (parameter, successFunc, errFunc) {
  return paramReq(api.getPraiseInfo, parameter, successFunc, errFunc)
}